import React from "react";
import { connect } from "react-redux";
import { Col, Layout, Row } from "antd";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import userService from "../../helpers/userService";
import { KEYCLOAK_USER_ROLES, BO_LOCATION_PATH } from "gsi-ui-components";
import { HIDDEN_SECTION } from "../../helpers/consts";
import BoHeader from "../layout/BoHeader";
import "antd/dist/antd.css";
import "./Home.less";

const { Content } = Layout;

const Home = ({ userRoles }) => {
  const hasBackofficeRole = userRoles.includes(KEYCLOAK_USER_ROLES.BACKOFFICE);
  const hasSupportRole = userRoles.includes(
    KEYCLOAK_USER_ROLES.SUPPORT_ENGINEER
  );
  const hasSalesRepRole = userRoles.includes(KEYCLOAK_USER_ROLES.SALES_REP);

  return (
    <Layout>
      <BoHeader title="Dashboard" />
      <Content>
        <div className="container dashboard">
          <Row type="flex" justify="center">
            <Col span={12}>
              <div className="header dashboard-header">
                <h2>
                  Welcome
                  <span className="dashboard-name">
                    {` ${userService.getName()} `}
                  </span>
                  <span>to the ProVoyance Backoffice Dashboard</span>
                </h2>
              </div>
            </Col>
          </Row>
          <Row type="flex" justify="center">
            <Col span={12}>
              <div>
                <p>Quick Links:</p>
                <ul>
                  {hasBackofficeRole && (
                    <li>
                      <Link to={BO_LOCATION_PATH.USERS}>All Users</Link>
                    </li>
                  )}
                  {hasBackofficeRole && (
                    <li>
                      <Link to={BO_LOCATION_PATH.ADD_USER}>Create User</Link>
                    </li>
                  )}
                  {hasSupportRole && !HIDDEN_SECTION && (
                    <li>
                      <Link to={BO_LOCATION_PATH.REQUEST_SUPPORT}>
                        List Support Tickets
                      </Link>
                    </li>
                  )}
                  {hasSupportRole && !HIDDEN_SECTION && (
                    <li>
                      <Link to={BO_LOCATION_PATH.REQUEST_SUPPORT_CASES}>
                        List Support Request Cases
                      </Link>
                    </li>
                  )}
                  {hasSalesRepRole && !HIDDEN_SECTION && (
                    <li>
                      <Link to={BO_LOCATION_PATH.CASE_LIST}>Cases List</Link>
                    </li>
                  )}
                  {hasBackofficeRole && (
                    <li>
                      <Link to={BO_LOCATION_PATH.ANALYTICS}>Analytics</Link>
                    </li>
                  )}
                  {hasBackofficeRole && (
                    <li>
                      <Link to={BO_LOCATION_PATH.NEWS}>News</Link>
                    </li>
                  )}
                  {hasBackofficeRole && (
                    <li>
                      <Link to={BO_LOCATION_PATH.NOTIFICATIONS}>
                        Release Notifications
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Content>
    </Layout>
  );
};

Home.propTypes = {
  userRoles: PropTypes.array
};

const mapStateToProps = state => {
  return {
    userRoles: state.user.userRoles
  };
};

export default connect(mapStateToProps, null)(Home);
