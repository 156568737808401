import React, { useEffect } from "react";
import {
  BarChartOutlined,
  FolderOpenOutlined,
  HomeOutlined,
  LogoutOutlined,
  UnorderedListOutlined,
  UserAddOutlined,
  UserOutlined,
  ReadOutlined,
  NotificationOutlined
} from "@ant-design/icons";
import { Layout, Menu } from "antd";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import { KEYCLOAK_USER_ROLES, BO_LOCATION_PATH } from "gsi-ui-components";
import { ReactComponent as ProvoyanceLogo } from "../../resources/layout/provoyance-isotype-white.svg";
import { changeLocation } from "../../actions/sidebar";
import { signOut } from "../../actions/auth";
import { loadImplantsInfo } from "../../actions/cases";
import { HIDDEN_SECTION } from "../../helpers/consts";
import "./SiderMenu.less";

const { Sider } = Layout;
const { SubMenu } = Menu;
const locationMenuItems = {
  [BO_LOCATION_PATH.ROOT]: "home",
  [BO_LOCATION_PATH.ADD_USER]: "user-add",
  [BO_LOCATION_PATH.REQUEST_SUPPORT]: "requestSupport",
  [BO_LOCATION_PATH.REQUEST_SUPPORT_CASES]: "requestSupportCases",
  [BO_LOCATION_PATH.CASE_TASK]: "requestSupport",
  [BO_LOCATION_PATH.SURGERY_CASES]: "requestSupport",
  [BO_LOCATION_PATH.CASE_LIST]: "userLoggedCaseList",
  [BO_LOCATION_PATH.FINISH_CASE_TASK]: "requestSupport",
  [BO_LOCATION_PATH.ANALYTICS]: "analytics",
  [BO_LOCATION_PATH.NEWS]: "news",
  [BO_LOCATION_PATH.NOTIFICATIONS]: "notifications"
};

const SiderMenu = ({
  changeLocation,
  locationHash,
  signOut,
  userRoles,
  loadImplantsInfo
}) => {
  const location = useLocation();
  const hasBackofficeRole = userRoles.includes(KEYCLOAK_USER_ROLES.BACKOFFICE);
  const hasSupportRole = userRoles.includes(
    KEYCLOAK_USER_ROLES.SUPPORT_ENGINEER
  );
  const hasSalesRepRole = userRoles.includes(KEYCLOAK_USER_ROLES.SALES_REP);

  if (hasBackofficeRole) {
    locationMenuItems[BO_LOCATION_PATH.USERS] = "all-users";
  } else if (hasSalesRepRole) {
    locationMenuItems[BO_LOCATION_PATH.USERS] = "users";
  }

  useEffect(() => {
    changeLocation(location.pathname);
  }, [changeLocation, location]);

  useEffect(() => {
    loadImplantsInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Sider collapsedWidth={"2.5rem"} collapsed={true}>
      <Menu
        className="navigation-menu"
        selectedKeys={[locationMenuItems[locationHash]]}
        mode="inline"
        collapsed="true"
        forceSubMenuRender={true}
      >
        <div className="layout-logo-container">
          <ProvoyanceLogo className="layout-logo" />
        </div>
        <Menu.Item key="home" className="layout-selected">
          <Link to={BO_LOCATION_PATH.ROOT} />
          <HomeOutlined className="layout-icon" />
          <span>Home</span>
        </Menu.Item>
        {hasBackofficeRole && (
          <Menu.Item key="all-users" className="layout-selected">
            <Link to={BO_LOCATION_PATH.USERS} />
            <UserOutlined className="layout-icon" />
            <span>All Users</span>
          </Menu.Item>
        )}
        {hasBackofficeRole && (
          <Menu.Item key="user-add" className="layout-selected">
            <Link to={BO_LOCATION_PATH.ADD_USER} />
            <UserAddOutlined className="layout-icon" />
            <span>Create User</span>
          </Menu.Item>
        )}
        {hasSupportRole && !HIDDEN_SECTION && (
          <Menu.Item key="requestSupport" className="layout-selected">
            <Link to={BO_LOCATION_PATH.REQUEST_SUPPORT} />
            <UnorderedListOutlined className="layout-icon" />
            <span>List Support Tickets</span>
          </Menu.Item>
        )}
        {hasSupportRole && !HIDDEN_SECTION && (
          <Menu.Item key="requestSupportCases" className="layout-selected">
            <Link to={BO_LOCATION_PATH.REQUEST_SUPPORT_CASES} />
            <FolderOpenOutlined className="layout-icon" />
            <span>Support Request Cases</span>
          </Menu.Item>
        )}
        {hasSalesRepRole && !HIDDEN_SECTION && (
          <Menu.Item key="userLoggedCaseList" className="layout-selected">
            <Link to={BO_LOCATION_PATH.CASE_LIST} />
            <FolderOpenOutlined className="layout-icon" />
            <span>Cases Library</span>
          </Menu.Item>
        )}
        {hasBackofficeRole && (
          <Menu.Item key="analytics" className="layout-selected">
            <Link to={BO_LOCATION_PATH.ANALYTICS} />
            <BarChartOutlined className="layout-icon" />
            <span>Analytics</span>
          </Menu.Item>
        )}
        {hasBackofficeRole && (
          <Menu.Item key="news" className="layout-selected">
            <Link to={BO_LOCATION_PATH.NEWS} />
            <ReadOutlined className="layout-icon" />
            <span>News</span>
          </Menu.Item>
        )}
        {hasBackofficeRole && (
          <Menu.Item key="notifications" className="layout-selected">
            <Link to={BO_LOCATION_PATH.NOTIFICATIONS} />
            <NotificationOutlined className="layout-icon" />
            <span>Release Notifications</span>
          </Menu.Item>
        )}
        <div className="menu-separator"></div>
        <SubMenu
          key="logout"
          className="layout-selected"
          title={
            <div className="layout-user-option">
              <UserOutlined className="layout-user-icon" />
            </div>
          }
        >
          <Menu.Item
            key="logout"
            className="logout-border-top"
            onClick={() => {
              signOut(createBrowserHistory());
            }}
          >
            <LogoutOutlined className="layout-user-icon" />
            <span>Logout</span>
          </Menu.Item>
        </SubMenu>
      </Menu>
    </Sider>
  );
};

SiderMenu.propTypes = {
  changeLocation: PropTypes.func,
  signOut: PropTypes.func,
  locationHash: PropTypes.string,
  userRoles: PropTypes.array,
  loadImplantsInfo: PropTypes.func
};

const mapStateToProps = state => {
  return {
    locationHash: state.sidebar.currentLocation,
    userRoles: state.user.userRoles
  };
};

export default React.memo(
  connect(mapStateToProps, { changeLocation, signOut, loadImplantsInfo })(
    SiderMenu
  )
);
