const buttonType = {
  PRIMARY: "primary",
  DEFAULT: "default",
  LINK: "link"
};

const buttonSize = {
  LARGE: "large",
  DEFAULT: "default",
  SMALL: "small"
};

const buttonShape = {
  CIRCLE: "circle",
  ROUND: "round"
};

export const buttonConsts = {
  TYPE: buttonType,
  SIZE: buttonSize,
  SHAPE: buttonShape
};

export const BO_PAGE_TITLE = "ProVoyance SI Backoffice";

export const ERROR_MESSAGE = "Something went wrong, please try again.";

export const HIDDEN_SECTION = process.env.REACT_APP_HIDDEN_SECTION ?? true;
